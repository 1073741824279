<template>
  <NuxtLink :to="formatLink(community.full_slug)" class="group">
    <figure
      class="ease-out-expo relative aspect-[258/344] overflow-clip rounded-lg transition-transform duration-700 group-hover:lg:-translate-y-2"
    >
      <div
        :style="{
          'background-color': community.content.backgroundDark?.color,
        }"
        class="ease-out-expo absolute inset-0 z-10 flex items-center justify-center opacity-70 transition-opacity duration-700 group-hover:opacity-0"
      >
        <NuxtImg
          v-if="community.content.logoDark?.filename"
          :src="community.content.logoDark.filename"
          :alt="community.content.logoDark.alt"
          class="ease-out-expo size-40 transition-transform duration-700 group-hover:scale-90"
        />
      </div>

      <NuxtImg
        v-if="community.content.cardBackgroundImage?.filename"
        :src="community.content.cardBackgroundImage.filename"
        :alt="community.content.cardBackgroundImage?.alt"
        width="384"
        class="ease-out-expo size-full object-cover transition-transform duration-700 group-hover:scale-105"
      />
    </figure>

    <div class="px-3 pt-4">
      <h3 v-if="suburbLabel" class="text-[1.125rem] font-medium text-gray-900">
        {{ suburbLabel }}
      </h3>

      <p class="text-secondary">
        {{ community.name }}
      </p>
    </div>
  </NuxtLink>
</template>

<script setup lang="ts">
import type { ISbStoryData } from 'storyblok'
import type { ContentTypeCommunityStoryblok } from '@/types/storyblok'
import { formatLink } from '@/utils/link-utils'

const props = defineProps<{
  community: ISbStoryData<ContentTypeCommunityStoryblok>
}>()
const suburbLabel = computed(() => {
  return (
    props.community.content.suburb ||
    props.community.content.displaySuiteLocation?.[0]?.suburb
  )
})
</script>
