<template>
  <div v-editable="blok" class="container flex flex-col gap-9">
    <div v-if="blok.showStateFilters">
      <ul class="flex items-center gap-x-1.5 overflow-auto lg:gap-x-3">
        <li v-for="filter in filters" :key="filter.label">
          <button
            :class="{
              'text-primary-500 border-green':
                currentFilter.label === filter.label,
              'border-gray-300 text-gray-700':
                currentFilter.label !== filter.label,
            }"
            class="ease-out-expo h-10 w-16 border-b-2 text-[14px] font-medium uppercase transition-colors duration-700 lg:h-12 lg:w-[4.375rem] lg:text-sm"
            @click="changeFilter(filter)"
          >
            {{ filter.label }}
          </button>
        </li>
      </ul>
    </div>

    <div class="pb-[--blockSpacing] lg:pb-0">
      <Swiper
        v-if="communities?.data.stories.length"
        :slides-per-view="1.45"
        :slides-offset-before="20"
        :slides-offset-after="20"
        :breakpoints="swiperBreakpoints"
        space-between="10px"
        touch-events-target="container"
        class="!overflow-visible px-5"
      >
        <SwiperSlide v-for="community in communityCards" :key="community.uuid">
          <CardCommunity :community="community" />
        </SwiperSlide>
      </Swiper>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SwiperOptions } from 'swiper/types'
import screens from '#tailwind-config/theme/screens'
import type { BlockCommunityListStoryblok } from '@/types/storyblok'

const props = defineProps<{ blok: BlockCommunityListStoryblok }>()
const states = await fetchAllStates()
const communities = await fetchAllCommunities()
const filters = computed<StateFilter[]>(() => [
  { label: 'ALL', fullSlug: 'all' },
  ...(states.value?.data.stories.map((state) => ({
    label: state.content.shortname,
    fullSlug: state.full_slug,
  })) || []),
])
const currentFilter = ref<StateFilter>(filters.value[0])
const swiperBreakpoints = ref<SwiperOptions['breakpoints']>({
  [stripPx(screens.sm)]: {
    slidesPerView: 2.45,
    spaceBetween: 10,
  },
  [stripPx(screens.lg)]: {
    slidesPerView: 4,
    spaceBetween: 36,
    slidesOffsetAfter: 0,
    slidesOffsetBefore: 0,
  },
})
const communityCards = computed(() => {
  if (
    !props.blok.showStateFilters &&
    isContentTypeStateStoryblok(props.blok.state)
  ) {
    return communities.value?.data.stories.filter(
      (community) =>
        isContentTypeStateStoryblok(props.blok.state) &&
        community.full_slug.startsWith(props.blok.state?.full_slug),
    )
  }

  if (currentFilter.value.label === 'ALL') {
    return communities.value?.data.stories || []
  }

  return communities.value?.data.stories.filter((community) =>
    community.full_slug.startsWith(currentFilter.value.fullSlug),
  )
})

function changeFilter(filter: StateFilter) {
  currentFilter.value = filter
}

interface StateFilter {
  label: string
  fullSlug: string
}
</script>
